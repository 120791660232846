import { FC } from 'react';
import {
    BooleanField,
    Datagrid,
    DateField,
    Identifier,
    LinearProgress,
    ReferenceManyField,
    ReferenceManyFieldProps,
    Tab,
    TabbedShowLayout,
    TextField,
    useGetResourceLabel,
    useLocale,
    useRecordContext,
} from 'react-admin';
import { Typography } from '@material-ui/core';

import AutoHidePagination from '@components/list/AutoHidePagination';
import AlertEmptyResource from '@components/list/AlertEmptyResource';
import RecordPriceField from '@components/field/RecordPriceField';
import BooleanTooltipField from '@components/field/BooleanTooltipField';
import SelloSalesPriceField from '@components/resource/sello_sales/field/SelloSalesPriceField';

import SelloSalesShowTab, { ActionField, ExpandSaleRow } from '../errand/ReclamationEditableDatagrid/SelloSalesShowTab';
import ExternalSalesTab from '../errand/ReclamationEditableDatagrid/ExternalSalesTab';
import BundleItemsTab from './BundleItemsTab';

import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import useFetchGet from '@js/hooks/useFetchGet';

import { Product } from '@js/interfaces/product';
import { SelloOrderRow } from '@js/interfaces/selloOrderRow';

const ProductExpand: FC<{
    id?: Identifier;
    record?: Product;
    resource?: string;
}> = (props) => {
    const record = useRecordContext(props);
    const translateField = useTranslateResourceField();
    const getResourceLabel = useGetResourceLabel();
    const isBundle = record?.splitType === 'bundle';

    return (
        <TabbedShowLayout syncWithLocation={false} record={record} resource={props.resource}>
            {isBundle && <BundleItemsTab label={translateField('bundleItems')} />}
            <Tab label={getResourceLabel('sello_sales')}>
                {isBundle ? <SelloRowsTab /> : <SelloSalesShowTab target="product" />}
            </Tab>
            <Tab label={getResourceLabel('external_sales')}>
                <ExternalSalesTab target="product" />
            </Tab>
        </TabbedShowLayout>
    );
};

const SelloRowsTab = (props: Omit<ReferenceManyFieldProps, 'children' | 'reference' | 'target'>) => {
    const getFieldLabel = useTranslateResourceField('sello_sales');
    const locale = useLocale();

    return (
        <ReferenceManyField
            {...props}
            perPage={10}
            target="product"
            reference="sello_order_rows"
            pagination={<AutoHidePagination />}
            sort={{ field: 'soldAt', order: 'desc' }}
        >
            <Datagrid empty={<AlertEmptyResource mb={2} />} expand={<ExpandSaleRow />}>
                <DateField source="soldAt" showTime label={getFieldLabel('createdAt')} />
                <TextField source="selloOrderId" sortable={false} label={getFieldLabel('selloOrderId')} />
                <TextField source="selloId" sortable={false} label={getFieldLabel('selloRowId')} />
                <TextField source="customerEmail" sortable={false} label={getFieldLabel('customerEmail')} />
                <TextField source="customerName" sortable={false} label={getFieldLabel('customerName')} />
                <IntegrationField source="integrationId" sortable={false} label={getFieldLabel('channel')} />
                <BooleanField source="paid" sortable={false} looseValue label={getFieldLabel('paid')} />
                <BooleanField source="delivered" sortable={false} looseValue label={getFieldLabel('delivered')} />
                <BooleanTooltipField<SelloOrderRow>
                    source="canceledAt"
                    label={getFieldLabel('canceledAt')}
                    valueLabelTrue={(r) => (r.canceledAt ? new Date(r.canceledAt).toLocaleString(locale) : undefined)}
                />
                <SelloSalesPriceField
                    sortable={false}
                    source="totalSalesPriceExcludeVat"
                    label={getFieldLabel('salesPrice')}
                />
                <RecordPriceField currencyField="currency" source="totalVat" label={getFieldLabel('totalVat')} />
                <ActionField />
            </Datagrid>
        </ReferenceManyField>
    );
};

const IntegrationField = (props: { source: string; record?: SelloOrderRow; sortable?: boolean; label?: string }) => {
    const record = useRecordContext(props) ?? {};
    const { data: integration, loading } = useFetchGet<{
        id: number;
        display_name: string;
    }>(
        `/api/sello_integrations/${record.integrationId}/${record.country}`,
        {},
        { enabled: !!(record.country && record.integrationId) },
    );

    if (loading) return <LinearProgress />;
    if (!integration?.display_name) return null;

    return (
        <Typography component="span" variant="body2">
            {integration.display_name}
        </Typography>
    );
};

export default ProductExpand;
